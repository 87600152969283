import Vue from "vue";
import Vuex from "vuex";
import Base from "./modules/base";
import Auth from "./modules/auth";
import Statistics from "./modules/statistic";
import Users from "./modules/user";
import Roles from "./modules/role";
import Warehouse from "./modules/warehouse";
import FAQ from "./modules/faqs";
import Promos from "./modules/promocode";
import Rattings from "./modules/rattings";
import Categories from "./modules/categories";
import Logs from "./modules/logs";
import Contacts from "./modules/contacts";
import Settings from "./modules/settings";
import Products from "./modules/products";
import BuyForMe from "./modules/buyForMe";
import Orders from "./modules/orders";
import Tickets from "./modules/tickets";
import Shelves from "./modules/shelves";
import Reports from "./modules/reports";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Base,
    Statistics,
    Auth,
    Users,
    Roles,
    Warehouse,
    FAQ,
    Promos,
    Rattings,
    Categories,
    Logs,
    Contacts,
    Settings,
    Products,
    BuyForMe,
    Orders,
    Tickets,
    Shelves,
    Reports,
  },
});
