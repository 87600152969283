import $http from "@/plugins/axios";

export default {
  state: {
    selectAll: false,
    take: 10,
    isLoading: false,
    btnLoading: false,
    isOpen: false,
    theme: null,
    showGlobalAlert: false,
    showModalAlert: false,
    globalAlerts: [],
    alertVariant: "secondary",
    alertMessage: "",
    dropdown: null,
  },
  getters: {
    take: (state) => state.take,
    selectAll: (state) => state.selectAll,
    isLoading: (state) => state.isLoading,
    btnLoading: (state) => state.btnLoading,
    isOpen: (state) => state.isOpen,
    theme: (state) => state.theme,
    showGlobalAlert: (state) => state.showGlobalAlert,
    showModalAlert: (state) => state.showModalAlert,
    globalAlerts: (state) => state.globalAlerts,
    alertVariant: (state) => state.alertVariant,
    alertMessage: (state) => state.alertMessage,
    dropdown: (state) => state.dropdown,
  },
  mutations: {
    PUSH_ALERT(state, data) {
      // if (state.globalAlerts.length > 3) {
      //   state.globalAlerts.unshift;
      //   state.globalAlerts.push(data);
      // } else {
      //   state.globalAlerts.push(data);
      // }
      state.globalAlerts = data;
    },
    TOGGLE_THEME(state, data) {
      state.theme = data;
    },
    TOGGLE_MENU(state) {
      state.isOpen = !state.isOpen;
    },
    CLOSE_MENU(state) {
      state.isOpen = false;
    },
    SET_STATE(state, payload) {
      state.selectAll = payload;
    },
    SET_PER_PAGE(state, take) {
      state.take = take;
    },
    TOGGLE_IS_LOADING(state, data) {
      state.isLoading = data;
    },
    TOGGLE_BTN_LOADING(state, data) {
      state.btnLoading = data;
    },
    SET_DROPDOWN(state, data) {
      state.dropdown = data;
    },
  },
  actions: {
    pushAlert({ commit }, payload) {
      commit("PUSH_ALERT", payload);
    },
    toggleTheme({ commit }, payload) {
      commit("TOGGLE_THEME", payload);
    },
    changeState({ commit }, payload) {
      commit("SET_STATE", payload);
    },
    toggleIsLoading({ commit }, payload) {
      commit("TOGGLE_IS_LOADING", payload);
    },
    async uploadImage({ commit, dispatch }, image) {
      dispatch("toggleBtnLoading", true);
      try {
        let data = new FormData();
        data.append("image", image);
        const res = await $http.post("/Dashboard/UploadImage", data);
        return { commit, imagePath: res.data.ResponseData };
      } catch (err) {
        console.log(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400 ||
          err.response.status === 413
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
      } finally {
        dispatch("toggleBtnLoading", false);
      }
    },
    toggleBtnLoading({ commit }, payload) {
      commit("TOGGLE_BTN_LOADING", payload);
    },
    async getDropdowns(_, payload) {
      try {
        const res = await $http.post("/dashboard/DictDropdown", {
          Key: payload.key,
        });
        // commit("SET_DROPDOWN", res.data.ResponseData);
        return res.data.ResponseData;
      } catch (e) {
        console.error(e);
      }
    },
    async uploadFile({ commit }, files) {
      const formData = new FormData();

      // Append all files to the FormData object
      files.forEach((file) => {
        formData.append("files", file);
      });
      let res = await $http.post("/UploadFile", formData);
      return { commit, res };
    },
    getPerPage({ commit }, take) {
      commit("SET_PER_PAGE", take);
    },
    toggleMenu({ commit }) {
      commit("TOGGLE_MENU");
    },
    closeMenu({ commit }) {
      commit("CLOSE_MENU");
    },
  },
};
