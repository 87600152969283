import $http from "@/plugins/axios.js";

export default {
  state: {
    tickets: null,
    ticketReplays: null,
  },
  getters: {
    tickets: (state) => state.tickets,
    ticketReplays: (state) => state.ticketReplays,
  },
  mutations: {
    SET_TICKETS(state, data) {
      state.tickets = data;
    },
    SET_TICKET_REPLAYS(state, data) {
      state.ticketReplays = data;
    },
  },
  actions: {
    async getTickets(
      { dispatch, commit },
      payload = { take: 10, page: 1, filters: { search: "" } }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/gettickets", {
          PageNumber: payload.page,
          PageSize: payload.take,
          Search: payload.filters.search,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_TICKETS", res.data.ResponseData);
      } catch (err) {
        dispatch("toggleIsLoading", false);
        commit("SET_TICKETS", null);
        throw err;
      }
    },
    async getTicketReplays({ dispatch, commit }, id) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/getticketreplies", {
          RefId: id,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_TICKET_REPLAYS", res.data.ResponseData);
      } catch (err) {
        dispatch("toggleIsLoading", false);
        commit("SET_TICKET_REPLAYS", null);
        throw err;
      }
    },
    async editTicket({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/editticket", payload);
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        dispatch("toggleBtnLoading", false);
        return;
      }
    },
    async sendReplay({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/AddTicketReply", payload);
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        dispatch("toggleBtnLoading", false);
        return;
      }
    },
  },
};
