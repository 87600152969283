import $http from "@/plugins/axios";

export default {
  state: {
    reports: [],
    report: {},
    query: [],
    executeData: [],
  },
  getters: {
    reports: (state) => state.reports,
    report: (state) => state.report,
    query: (state) => state.query,
    executeData: (state) => state.executeData,
  },
  mutations: {
    SET_REPORTS(state, data) {
      state.reports = data;
    },
    SET_REPORT(state, data) {
      state.report = data;
    },
    SET_QUERY(state, data) {
      state.query = data;
    },
    SET_EXECUTE(state, data) {
      state.executeData = data;
    },
  },
  actions: {
    async getReports(
      { commit },
      payload = {
        PageNumber: 1,
        PageSize: 10,
        Search: "",
      }
    ) {
      let res = await $http.post("/Dashboard/GetReports", payload);
      commit("SET_REPORTS", res.data);
    },
    async getReport({ commit }, id) {
      let res = await $http.post("/Dashboard/GetReport", id);
      commit("SET_REPORT", res.data);
    },
    async getReportQuery({ commit }, payload) {
      let res = await $http.post("/Dashboard/getreportquery", payload);
      commit("SET_QUERY", res.data);
    },
    async addReport({ commit }, payload) {
      await $http.post("/Dashboard/addreport", payload);
      return commit();
    },
    async editReport({ commit }, payload) {
      await $http.post("/Dashboard/editReport", payload);
      return commit();
    },
    async deleteReport({ commit }, payload) {
      await $http.post("/Dashboard/deleteReport", payload);
      return commit();
    },
    async editReportQuery({ commit }, payload) {
      await $http.post("/Dashboard/editreportquery", payload);
      return commit();
    },
    async excuteReport({ commit }, payload) {
      let res = await $http.post("/Dashboard/excutereport", payload);
      return commit("SET_EXECUTE", res.data);
    },
  },
};
