import $http from "@/plugins/axios.js";

export default {
  state: {
    orders: null,
    orderLogs: null,
  },
  getters: {
    orders: (state) => state.orders,
    orderLogs: (state) => state.orderLogs,
  },
  mutations: {
    SET_ORDERS(state, data) {
      state.orders = data;
    },
    SET_ORDER_LOGS(state, data) {
      state.orderLogs = data;
    },
  },
  actions: {
    async getOrders(
      { dispatch, commit },
      payload = {
        take: 10,
        page: 1,
        filters: {
          OrderStatusID: null,
          TrackingStatusID: null,
          OrderTypeID: null,
          FullNameOrUserName: "",
          PhoneNumber: "",
          ID: null,
        },
      }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/GetOrders", {
          PageNumber: payload.page,
          PageSize: payload.take,
          Search: payload.filters.search ? payload.filters.search : "",
          OrderStatusID: payload.filters.OrderStatusID,
          OrderType: payload.filters.OrderTypeID,
          TrackingStatusID: payload.filters.TrackingStatusID,
          FullNameOrUserName: payload.filters.UserName,
          PhoneNumber: payload.filters.PhoneNumber,
          ID: payload.filters.ID == "" ? null : payload.filters.ID,
          MinTotalAmount:
            payload.filters.MinTotalAmount == ""
              ? null
              : payload.filters.MinTotalAmount,
          MaxTotalAmount:
            payload.filters.MaxTotalAmount == ""
              ? null
              : payload.filters.MaxTotalAmount,
          SearchFullName:
            payload.filters.SearchFullName == ""
              ? null
              : payload.filters.SearchFullName,
          SearchUsername:
            payload.filters.SearchUsername == ""
              ? null
              : payload.filters.SearchUsername,
          SearchPhoneNumber:
            payload.filters.SearchPhoneNumber == ""
              ? null
              : payload.filters.SearchPhoneNumber,
          SearchEmail:
            payload.filters.SearchEmail == ""
              ? null
              : payload.filters.SearchEmail,
          SearchAddress:
            payload.filters.SearchAddress == ""
              ? null
              : payload.filters.SearchAddress,
          SearchLocation:
            payload.filters.SearchLocation == ""
              ? null
              : payload.filters.SearchLocation,
          GetType: "UsingAPIandSAR",
        });
        dispatch("toggleIsLoading", false);
        commit("SET_ORDERS", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_ORDERS", null);
      }
    },
    async getOrdersShipping(
      { dispatch, commit },
      payload = {
        take: 10,
        page: 1,
        filters: {
          OrderStatusID: null,
          OrderTypeID: null,
          TrackingStatusID: null,
          FullNameOrUserName: "",
          PhoneNumber: "",
          ID: null,
        },
      }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/GetOrders", {
          PageNumber: payload.page,
          PageSize: payload.take,
          Search: payload.filters.search ? payload.filters.search : "",
          OrderStatusID: payload.filters.OrderStatusID,
          OrderType: payload.filters.OrderTypeID,
          TrackingStatusID: payload.filters.TrackingStatusID,
          FullNameOrUserName: payload.filters.UserName,
          PhoneNumber: payload.filters.PhoneNumber,
          ID: payload.filters.ID == "" ? null : payload.filters.ID,
          MinTotalAmount:
            payload.filters.MinTotalAmount == ""
              ? null
              : payload.filters.MinTotalAmount,
          MaxTotalAmount:
            payload.filters.MaxTotalAmount == ""
              ? null
              : payload.filters.MaxTotalAmount,
          SearchFullName:
            payload.filters.SearchFullName == ""
              ? null
              : payload.filters.SearchFullName,
          SearchUsername:
            payload.filters.SearchUsername == ""
              ? null
              : payload.filters.SearchUsername,
          SearchPhoneNumber:
            payload.filters.SearchPhoneNumber == ""
              ? null
              : payload.filters.SearchPhoneNumber,
          SearchEmail:
            payload.filters.SearchEmail == ""
              ? null
              : payload.filters.SearchEmail,
          SearchAddress:
            payload.filters.SearchAddress == ""
              ? null
              : payload.filters.SearchAddress,
          SearchLocation:
            payload.filters.SearchLocation == ""
              ? null
              : payload.filters.SearchLocation,
          GetType: "Shipping",
        });
        dispatch("toggleIsLoading", false);
        commit("SET_ORDERS", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_ORDERS", null);
      }
    },
    async getOrderLogs(
      { dispatch, commit },
      payload = {
        take: 10,
        page: 1,
        id: null,
      }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/GetOrderLogs", {
          PageNumber: payload.page,
          PageSize: payload.take,
          ID: payload.id,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_ORDER_LOGS", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_ORDER_LOGS", null);
      }
    },
    async changeOrderStatusAPI({ commit, dispatch }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/ChangeStatus", {
          EditEntityId: payload.id,
          OrderStatusID: payload.status,
          ServiceAmount: payload.serviceCost,
          DeliveryAmount: payload.deliveryCost,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        dispatch("toggleBtnLoading", false);
        return;
      }
    },
  },
};
