import Vue from "vue";
import VueRouter from "vue-router";
// import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: `/home`,
  },
  {
    path: "*",
    name: "404",
    component: () => import("@/views/PageNotFound.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Auth/LoginPage.vue"),
    meta: {
      permission: [],
    },
  },
  {
    path: "/",
    name: "LayoutPage",
    component: () => import("../views/LayoutPage.vue"),
    // redirect: "/warehouses",
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("@/views/HomeView.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "reports",
        name: "reports",
        component: () => import("@/views/ReportsView.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "report/:id",
        name: "report",
        component: () => import("@/views/ReportView.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "/users",
        name: "Users",
        component: () => import("@/views/Users.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "/warehouses",
        name: "warehouses",
        component: () => import("@/views/WarehousesPage.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "/faqs",
        name: "faqs",
        component: () => import("@/views/FAQPage.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "/promocodes",
        name: "promocodes",
        component: () => import("@/views/PromocodesPage.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "/rattings",
        name: "rattings",
        component: () => import("@/views/RattingPage.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "/logs",
        name: "LogPage",
        component: () => import("@/views/LogPage.vue"),
        meta: {
          permission: ["AudMnu"],
        },
      },
      {
        path: "/roles",
        name: "RolesPage",
        component: () => import("@/views/RolesPage.vue"),
        meta: {
          permission: ["RolMnu"],
        },
      },
      {
        path: "/settings",
        name: "SettingsPage",
        component: () => import("@/views/SettingsPage.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "/categories",
        name: "CategoriesPage",
        component: () => import("@/views/CategoriesPage.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "/contacts",
        name: "ContactsPage",
        component: () => import("@/views/ContactPage.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "/products",
        name: "ProductsPage",
        component: () => import("@/views/ProductsPage.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "/orders",
        name: "OrdersPage",
        component: () => import("@/views/OrdersPage.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "/orders-shipping",
        name: "OrdersShippingPage",
        component: () => import("@/views/OrdersShippingPage.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "/buy-for-me",
        name: "BuyForMePage",
        component: () => import("@/views/BuyForMePage.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "/tickets",
        name: "TicketsPage",
        component: () => import("@/views/TicketsPage.vue"),
        meta: {
          permission: [],
        },
      },
      // {
      //   path: "/reports",
      //   name: "ReportsPage",
      //   component: () => import("@/views/ReportsPage.vue"),
      //   meta: {
      //     permission: [],
      //   },
      // },
      {
        path: "/shelves",
        name: "ShelvesPage",
        component: () => import("@/views/ShelvesPage.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "/shelves/:id",
        name: "ShelvesPageById",
        component: () => import("@/views/ShelvesPage.vue"),
        meta: {
          permission: [],
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// function checkPermission(permission) {
//   let permissions = localStorage.getItem("permissions")
//     ? JSON.parse(localStorage.getItem("permissions"))
//     : [];
//   store.dispatch("updateUserPermissions", permissions);
//   if (!permission.length) return true;
//   for (let i = 0; i < permission.length; i++) {
//     if (store.getters.userPermissions.has(permission[i])) {
//       return true;
//     }
//   }
//   return false;
// }

// router.beforeEach((to, from, next) => {
//   const isAuthenticated = Boolean(localStorage.getItem("token"));
//   const isAuthorized = Boolean(localStorage.getItem("permissions"));
//   if (to.name !== "login" && (!isAuthenticated || !isAuthorized)) {
//     if (from.name !== "login") {
//       const redirectPath = "/login";
//       const query = { redirect: to.fullPath }; // Store the intended path as a query parameter
//       // const redirectToLogin = () =>
//       next({ path: redirectPath, query, replace: true });
//       // authenticateUser()
//       //   .then(() => {
//       //     redirectToLogin();
//       //   })
//       //   .catch(() => {
//       //     next();
//       //   });
//     } else {
//       next();
//     }
//   } else {
//     // check permissions
//     const hasPermissions = checkPermission(to.meta.permission);
//     if (hasPermissions) {
//       // next({ path: to.fullPath });
//       next();
//     } else {
//       next({
//         path: from.path,
//         replace: true,
//       });
//     }
//   }
// });

export default router;
