var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alerts-container"},[_c('div',{staticClass:"alerts"},[_c('b-alert',{attrs:{"show":_vm.globalAlerts.duration,"dismissible":"","fade":""},on:{"dismissed":function($event){_vm.globalAlerts.duration = 0}}},[_c('div',{staticClass:"alert-content"},[_c('div',{staticClass:"icon",class:{
            'text-success': _vm.globalAlerts.alertVariant == 'success',
            'text-primary': _vm.globalAlerts.alertVariant == 'primary',
            'text-danger': _vm.globalAlerts.alertVariant == 'danger',
            'text-warning': _vm.globalAlerts.alertVariant == 'warning',
            'text-info': _vm.globalAlerts.alertVariant == 'info',
          }},[_c('i',{class:{
              'fa-regular fa-circle-check':
                _vm.globalAlerts.alertVariant == 'success',
              'fa-solid fa-spinner fa-spin':
                _vm.globalAlerts.alertVariant == 'primary',
              'fa-solid fa-xmark': _vm.globalAlerts.alertVariant == 'danger',
              'fa-solid fa-triangle-exclamation':
                _vm.globalAlerts.alertVariant == 'warning',
              'fa-solid fa-exclamation': _vm.globalAlerts.alertVariant == 'info',
            }})]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.globalAlerts.alertMessage)+" ")])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }