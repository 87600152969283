import $http from "@/plugins/axios.js";

export default {
  state: {
    warehouses: null,
    warehouse: null,
  },
  getters: {
    warehouses: (state) => state.warehouses,
    warehouse: (state) => state.warehouse,
  },
  mutations: {
    SET_WAREHOUSES(state, data) {
      state.warehouses = data;
    },
    SET_WAREHOUSE(state, data) {
      state.warehouse = data;
    },
  },
  actions: {
    async getWarehouses(
      { dispatch, commit },
      payload = { take: 10, page: 1, filters: {} }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/Dashboard/GetWarehouses", {
          PageNumber: payload.page,
          PageSize: payload.take,
          Name: payload.filters.name ? payload.filters.name : null,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_WAREHOUSES", res.data.ResponseData);
      } catch (err) {
        dispatch("toggleIsLoading", false);
        commit("SET_WAREHOUSES", null);
        throw err;
      }
    },
    async getWarehouseByID({ commit, dispatch }, id) {
      dispatch("toggleIsLoading", true);
      try {
        const res = await $http.post("/Dashboard/GetWarehouse", {
          Id: id,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_WAREHOUSE", res.data.ResponseData[0]);
      } catch (err) {
        dispatch("toggleIsLoading", false);
        commit("SET_WAREHOUSE", null);
        throw err;
      }
    },
    async addWarehouse({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/Dashboard/AddWarehouse", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    async editWarehouse({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/Dashboard/EditWarehouse", payload);
        dispatch("toggleBtnLoading", false);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        return commit;
      } catch (err) {
        dispatch("toggleBtnLoading", false);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        throw err;
      }
    },
    async deleteWarehouse({ dispatch, commit }, id) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/Dashboard/DeleteWarehouse", { Id: id });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        return;
      }
    },
  },
};
