import $http from "@/plugins/axios.js";

export default {
  state: {
    role: null,
    roles: null,
    permissions: null,
  },
  getters: {
    role: (state) => state.role,
    roles: (state) => state.roles,
    permissions: (state) => state.permissions,
    groupedData: (state) => {
      const groups = {};
      state.permissions.forEach((item) => {
        const groupId = item.GROUP_ID;
        if (!groups[groupId]) {
          groups[groupId] = [];
        }
        groups[groupId].push(item);
      });
      return groups;
    },
  },
  mutations: {
    SET_ROLE(state, data) {
      state.role = data;
    },
    SET_ROLES(state, data) {
      state.roles = data;
    },
    SET_PERMISSIONS(state, data) {
      state.permissions = data;
    },
  },
  actions: {
    async getRole({ commit }, payload = { id: null }) {
      try {
        let res = await $http.post("/dashboard/getrole", { Id: payload.id });
        commit("SET_ROLE", res.data.ResponseData[0]);
      } catch (err) {
        console.error(err);
        commit("SET_ROLE", null);
      }
    },
    async getRoles(
      { dispatch, commit },
      payload = { take: 10, page: 1, name: "" }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/getroles", {
          PageNumber: payload.page,
          PageSize: payload.take,
          Name: payload.name ? payload.name : "",
        });
        dispatch("toggleIsLoading", false);
        commit("SET_ROLES", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_ROLES", null);
      }
    },
    async getClaims({ dispatch, commit }, payload = { take: 1000, page: 1 }) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/getclaims", {
          PageNumber: payload.page,
          PageSize: payload.take,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_PERMISSIONS", res.data.ResponseData.Data);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_PERMISSIONS", null);
      }
    },
    async addRole({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/addrole", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    async editRole({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/editrole", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم تعديل البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    async deleteRole({ dispatch, commit }, id) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/deleterole", { Id: id });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        return;
      }
    },
  },
};
