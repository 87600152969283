import $http from "@/plugins/axios.js";

export default {
  state: {
    contacts: null,
  },
  getters: {
    contacts: (state) => state.contacts,
  },
  mutations: {
    SET_CONTACTS(state, data) {
      state.contacts = data;
    },
  },
  actions: {
    async getContacts(
      { dispatch, commit },
      payload = { take: 10, page: 1, filters: {} }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/getcontacts", {
          PageNumber: payload.page,
          PageSize: payload.take,
          Search: payload.filters.search ? payload.filters.search : "",
        });
        dispatch("toggleIsLoading", false);
        commit("SET_CONTACTS", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_CONTACTS", null);
      }
    },
  },
};
