import Vue from "vue";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";

function loadLocale(code) {
  return import(`vee-validate/dist/locale/${code}.json`).then((locale) => {
    localize(code, locale);
  });
}
// Set default language
loadLocale("ar");

// Install rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

// Custom rules
extend("password", {
  validate: (value) => {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      };
    }

    return {
      valid: value.length >= 8 && value.length <= 12,
    };
  },
  message:
    "The {_field_} field must be a valid password and minimum character of 8 and maximum character of 12",
});

// Install components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
