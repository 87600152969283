<template>
  <div id="app" :class="$i18n.locale === 'ar' ? 'rtl' : 'ltr'">
    <router-view />
    <alert />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Alert from "@/components/layout/_alert.vue";
export default {
  components: {
    Alert,
  },
  data() {
    return {
      portNumber: null,
    };
  },
  metaInfo() {
    return {
      title: "9ar Project",
    };
  },
  watch: {
    theme() {
      let htmlElement = document.documentElement;
      htmlElement.setAttribute("theme", this.theme);
    },
    "$i18n.locale": {
      handler(newLocale) {
        document.documentElement.lang = newLocale;
        document.documentElement.dir = newLocale === "ar" ? "rtl" : "ltr";
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["theme"]),
  },
  methods: {
    ...mapActions(["toggleTheme"]),
  },
  beforeCreate() {
    this.$OneSignal.User.PushSubscription.optIn();
  },
  mounted() {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", "darker");
    }
    this.toggleTheme(localStorage.getItem("theme"));
    let htmlElement = document.documentElement;
    htmlElement.setAttribute("theme", this.theme);
  },
};
</script>
