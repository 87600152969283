import axios from "axios";
import router from "@/router";

// export const baseUrl = "http://sarproject-001-site1.etempurl.com/api";
export const baseUrl = "https://api.9arstore.com/api";
export const filesUrl = "https://api.9arstore.com/Image/";

axios.interceptors.request.use(
  (config) => {
    config.baseURL = baseUrl;

    config.headers = {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("token")) || false
      }`,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user_info");
      router.push({ name: "login" });
    }
    return Promise.reject(error);
  }
);

export default axios;
