import $http from "@/plugins/axios.js";
import router from "@/router";

const decodToken = (token) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  } catch (e) {
    throw new Error("Token is not valid!");
  }
};

export default {
  state: {
    userInfo: {},
    authError: "",
    me: {},
    userPermisstions:
      JSON.parse(localStorage.getItem("sar-permisstions")) || [],
    userPermissions: new Set(),
  },
  getters: {
    userInfo: (state) => state.userInfo,
    me: (state) => state.me,
    userPermissions: (state) => state.userPermissions,
  },
  actions: {
    async getMe({ commit }) {
      const request = await $http.get("/auth");
      commit("SET_ME", request.data);
      return request.data;
    },

    async login({ commit }, data) {
      try {
        const res = await $http.post("/dashboard/login", data);
        localStorage.setItem(
          "token",
          JSON.stringify(res.data.ResponseData.JWT)
        );
        localStorage.removeItem("tableOrders");
        localStorage.removeItem("tableProducts");
        if (router.currentRoute.query.redirect) {
          router.push(router.currentRoute.query.redirect);
        } else {
          router.push("/home");
        }
        localStorage.setItem(
          "sar-permisstions",
          JSON.stringify(
            decodToken(res.data.ResponseData.JWT)["9910897105109115"]
          )
        );
        commit("SET_AUTH_ERROR", "");
        commit("SET_USER", res.data);
      } catch (e) {
        if (e.response.status === 500 || e.response.status === 400) {
          commit("SET_AUTH_ERROR", e.response.data.StatusDescription);
        } else if (e.response.status === 403) {
          commit("SET_AUTH_ERROR", e.response.data.StatusDescription);
        }
      }
    },

    updateUserPermissions({ commit }, payload) {
      commit("SET_USER_PERMISSIONS", payload);
    },

    logout({ commit }) {
      try {
        // await $http.post("/auth/user/logout");
        localStorage.removeItem("token");
        localStorage.removeItem("user_info");
        localStorage.removeItem("permissions");
        router.push({ name: "login" });
        return commit;
      } catch (e) {
        console.log(e);
      }
    },
  },
  mutations: {
    SET_USER(state, data) {
      state.userInfo = data.user;
    },
    SET_ME(state, data) {
      state.me = data;
    },
    SET_USER_PERMISSIONS(state, data) {
      state.userPermissions = new Set();
      data.forEach((p) => {
        state.userPermissions.add(p.key);
      });
    },
    SET_AUTH_ERROR(state, error) {
      state.authError = error;
    },
  },
};
