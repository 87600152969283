import $http from "@/plugins/axios.js";

export default {
  state: {
    buyForMe: null,
  },
  getters: {
    buyForMe: (state) => state.buyForMe,
  },
  mutations: {
    SET_BUY_FOR_ME(state, data) {
      state.buyForMe = data;
    },
  },
  actions: {
    async getBuyForMe(
      { dispatch, commit },
      payload = { take: 10, page: 1, filters: {} }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/Dashboard/GetRequests", {
          PageNumber: payload.page,
          PageSize: payload.take,
          Search: payload.filters?.search,
          Date: payload.filters?.date == "" ? null : payload.filters.date,
          SearchRequestNumber:
            payload.filters?.requestNumber == ""
              ? null
              : payload.filters.requestNumber,
          SearchPrice:
            payload.filters?.SearchPrice == ""
              ? null
              : payload.filters.SearchPrice,

          BuyForMeStatusID: payload.filters?.statusID,
          SearchFullName:
            payload.filters.SearchFullName == ""
              ? null
              : payload.filters.SearchFullName,
          SearchUsername:
            payload.filters.SearchUsername == ""
              ? null
              : payload.filters.SearchUsername,
          SearchPhoneNumber:
            payload.filters.SearchPhoneNumber == ""
              ? null
              : payload.filters.SearchPhoneNumber,
          SearchItemUrl:
            payload.filters.SearchItemUrl == ""
              ? null
              : payload.filters.SearchItemUrl,
          SearchDescription:
            payload.filters.SearchDescription == ""
              ? null
              : payload.filters.SearchDescription,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_BUY_FOR_ME", res.data.ResponseData);
      } catch (err) {
        dispatch("toggleIsLoading", false);
        commit("SET_BUY_FOR_ME", null);
        throw err;
      }
    },
    async changeRequestStatusAPI({ commit, dispatch }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/ChangeStatusBuyForMe", {
          EditEntityId: payload.id,
          BuyForMeStatusID: payload.status,
          DownPayment: payload.downPayment,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        dispatch("toggleBtnLoading", false);
        return;
      }
    },
    async setProductPrice({ commit, dispatch }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/DeterminePrice", {
          EditEntityId: payload.id,
          ProductPrice: payload.productPrice,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        dispatch("toggleBtnLoading", false);
        return;
      }
    },
  },
};
