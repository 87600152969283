import $http from "@/plugins/axios.js";

export default {
  state: {
    products: null,
    product: null,
  },
  getters: {
    products: (state) => state.products,
    product: (state) => state.product,
  },
  mutations: {
    SET_PRODUCTS(state, data) {
      state.products = data;
    },
    SET_PRODUCT(state, data) {
      state.product = data;
    },
  },
  actions: {
    async getProducts(
      { dispatch, commit },
      payload = { take: 10, page: 1, filters: {} }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/Dashboard/GetProducts", {
          PageNumber: payload.page,
          PageSize: payload.take,
          ProductSatusID: payload.filters.productStatus,
          RequestTypeID: payload.filters.requestType,
          Search: payload.filters.search ? payload.filters.search : "",
          MinPrice:
            payload.filters.MinPrice === "" ? null : payload.filters.MinPrice,
          MaxPrice:
            payload.filters.MaxPrice === "" ? null : payload.filters.MaxPrice,
          SearchUsername:
            payload.filters.SearchUsername === ""
              ? null
              : payload.filters.SearchUsername,
          SearchFullName:
            payload.filters.SearchFullName === ""
              ? null
              : payload.filters.SearchFullName,
          SearchCategoryName:
            payload.filters.SearchCategoryName === ""
              ? null
              : payload.filters.SearchCategoryName,
          SearchWarehouseName:
            payload.filters.SearchWarehouseName === ""
              ? null
              : payload.filters.SearchWarehouseName,
          SearchTitle:
            payload.filters.SearchTitle === ""
              ? null
              : payload.filters.SearchTitle,
          SearchDescription:
            payload.filters.SearchDescription === ""
              ? null
              : payload.filters.SearchDescription,
          SearchCurrency:
            payload.filters.SearchCurrency === ""
              ? null
              : payload.filters.SearchCurrency,
          SearchShelf:
            payload.filters.SearchShelf === ""
              ? null
              : payload.filters.SearchShelf,
          WarehouseId:
            payload.filters.WarehouseId === ""
              ? null
              : payload.filters.WarehouseId,
          CategoryId:
            payload.filters.CategoryId === ""
              ? null
              : payload.filters.CategoryId,
          ShelfId:
            payload.filters.ShelfId === "" ? null : payload.filters.ShelfId,
          BuyForMeFkId:
            payload.filters.requestNumber == ""
              ? null
              : payload.filters.requestNumber,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_PRODUCTS", res.data.ResponseData);
      } catch (err) {
        dispatch("toggleIsLoading", false);
        commit("SET_PRODUCTS", null);
        throw err;
      }
    },
    async getProductByID({ commit, dispatch }, id) {
      dispatch("toggleIsLoading", true);
      try {
        const res = await $http.post("/dashboard/GetProduct", {
          Id: id,
          Language: "AR",
        });
        dispatch("toggleIsLoading", false);
        commit("SET_PRODUCT", res.data.ResponseData[0]);
      } catch (err) {
        dispatch("toggleIsLoading", false);
        commit("SET_PRODUCT", null);
        throw err;
      }
    },
    async addProduct({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/AddProduct", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    async editProduct({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/EditProduct", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        console.error(err);
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    async deleteProduct({ dispatch, commit }, id) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/DeleteProduct", { Id: id });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        return;
      }
    },
  },
};
