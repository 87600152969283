import $http from "@/plugins/axios.js";

export default {
  state: {
    logs: null,
  },
  getters: {
    logs: (state) => state.logs,
  },
  mutations: {
    SET_LOGS(state, data) {
      state.logs = data;
    },
  },
  actions: {
    async getLogs(
      { dispatch, commit },
      payload = { take: 10, page: 1, userType: "ADMIN" }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/getaudits", {
          PageNumber: payload.page,
          PageSize: payload.take,
          UserType: payload.userType,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_LOGS", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_LOGS", null);
      }
    },
  },
};
