import $http from "@/plugins/axios.js";

export default {
  state: {
    categoreis: null,
    category: null,
  },
  getters: {
    categoreis: (state) => state.categoreis,
    category: (state) => state.category,
  },
  mutations: {
    SET_CATEGORIES(state, data) {
      state.categoreis = data;
    },
    SET_CATEGORY(state, data) {
      state.category = data;
    },
  },
  actions: {
    async getCategoreis(
      { dispatch, commit },
      payload = { take: 10, page: 1, filters: {} }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/Dashboard/GetCategories", {
          PageNumber: payload.page,
          PageSize: payload.take,
          Name: payload.filters?.name ? payload.filters.name : "",
        });
        dispatch("toggleIsLoading", false);
        commit("SET_CATEGORIES", res.data.ResponseData);
      } catch (err) {
        dispatch("toggleIsLoading", false);
        commit("SET_CATEGORIES", null);
        throw err;
      }
    },
    async getCategoryByID({ commit, dispatch }, id) {
      dispatch("toggleIsLoading", true);
      try {
        const res = await $http.post("/Dashboard/GetCategory", {
          Id: id,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_CATEGORY", res.data.ResponseData[0]);
      } catch (err) {
        dispatch("toggleIsLoading", false);
        commit("SET_CATEGORY", null);
        throw err;
      }
    },
    async addCategory({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/Dashboard/AddCategory", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    async editCategory({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/Dashboard/EditCategory", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    async deleteCategory({ dispatch, commit }, id) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/Dashboard/DeleteCategory", { Id: id });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        return;
      }
    },
  },
};
