import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import OneSignalVue from "onesignal-vue";

import { BootstrapVue } from "bootstrap-vue";
import VueSweetalert2 from "vue-sweetalert2";
import VueMeta from "vue-meta";
import VueQriously from "vue-qriously";

import Print from "vue-print-nb";
import excel from "vue-excel-export";
import VueExcelXlsx from "vue-excel-xlsx";
import VueQuillEditor from "vue-quill-editor";

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-select/dist/vue-select.css";
import "@/assets/sass/main.scss";

import vSelect from "vue-select";
import * as VueGoogleMaps from "vue2-google-maps";
import i18n from "./i18n";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDXJc0qOauH6XEIS0gUKnNHsmJTfcSScRU",
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
    installComponents: true,
  },
});

Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(VueExcelXlsx);
Vue.use(Print);
Vue.use(excel);
Vue.use(VueMeta);
Vue.use(VueQriously);
Vue.use(require("vue-moment"));
Vue.component("v-select", vSelect);
Vue.use(VueQuillEditor /* { default global options } */);

require("@/plugins/validation");

Vue.config.productionTip = false;

let mixin = {
  methods: {
    checkRole(role) {
      if (role.length == 0) {
        return true;
      } else {
        return JSON.parse(localStorage.getItem("sar-permisstions"))
          .split(",")
          .some((item) => role.includes(item));
      }
    },
  },
};

// var OneSignal = window.OneSignal || [];
// OneSignal.push(function () {
//   OneSignal.init({
//     appId: "97101ea8-9882-4b17-b896-257e06c4dec5",
//   });
// });

Vue.use(OneSignalVue);
Vue.mixin(mixin);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  // beforeMount() {
  //   this.$OneSignal.init({ appId: "97101ea8-9882-4b17-b896-257e06c4dec5" });
  // },
}).$mount("#app");
