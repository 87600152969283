import $http from "@/plugins/axios.js";

export default {
  state: {
    faqs: null,
  },
  getters: {
    faqs: (state) => state.faqs,
  },
  mutations: {
    SET_FAQS(state, data) {
      state.faqs = data;
    },
  },
  actions: {
    async getFaqs(
      { dispatch, commit },
      payload = { take: 10, page: 1, filters: {} }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/getfaqs", {
          PageNumber: payload.page,
          PageSize: payload.take,
          Search: payload.filters.search ? payload.filters.search : "",
        });
        dispatch("toggleIsLoading", false);
        commit("SET_FAQS", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_FAQS", null);
      }
    },
    async addFaq({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/addfaq", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    async editFaq({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/editfaq", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    async deleteFaq({ dispatch, commit }, id) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/deleteFaq", { Id: id });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        return;
      }
    },
  },
};
