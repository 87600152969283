import $http from "@/plugins/axios";
export default {
  state: {
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    UsersStatistics: [],
    OrdersStatistics: {
      ResponseData: {
        OrderByMonth: [],
      },
    },
    RateingStatistics: [],
    BuyForMeStatistics: {
      ResponseData: {
        RequestByMonth: [],
      },
    },
  },
  getters: {
    UsersStatistics: (state) => state.UsersStatistics,
    OrdersStatistics: (state) => state.OrdersStatistics,
    OrdersStatisticsChart: (state) => {
      let labels = [];
      let data = [];
      state.OrdersStatistics?.ResponseData?.OrderByMonth.forEach((el) => {
        labels.push(state.months[el.Month - 1]);
        data.push(el.Count);
      });
      return {
        labels: labels,
        datasets: [
          {
            label: "Order Statistic",
            backgroundColor: "#f87979",
            data: data,
          },
        ],
      };
    },
    RateingStatistics: (state) => state.RateingStatistics,
    BuyForMeStatistics: (state) => state.BuyForMeStatistics,
    BuyForMeStatisticsChart: (state) => {
      let labels = [];
      let data = [];
      state.BuyForMeStatistics?.ResponseData?.RequestByMonth.forEach((el) => {
        labels.push(state.months[el.Month - 1]);
        data.push(el.Count);
      });
      return {
        labels: labels,
        datasets: [
          {
            label: "Order Statistic",
            backgroundColor: "#f87979",
            data: data,
          },
        ],
      };
    },
  },
  mutations: {
    SORT_USER_STATISTICS(state, date) {
      state.UsersStatistics = date;
    },
    SORT_ORDERS_STATISTICS(state, date) {
      state.OrdersStatistics = date;
    },
    SORT_RATEING_STATISTICS(state, date) {
      state.RateingStatistics = date;
    },
    SORT_BUYFORME_STATISTICS(state, date) {
      state.BuyForMeStatistics = date;
    },
  },
  actions: {
    async getUsersStatistics({ commit }) {
      let res = await $http.post("/Dashboard/UsersStatistics");
      commit("SORT_USER_STATISTICS", res.data);
    },
    async getOrdersStatistics(
      { commit },
      filters = {
        FromDate: new Date().toISOString(),
        ToDate: new Date().toISOString(),
      }
    ) {
      if (filters.FromDate === "") {
        filters.FromDate = filters.ToDate;
      } else if (filters.ToDate === "") {
        filters.ToDate = filters.FromDate;
      }
      let res = await $http.post("/Dashboard/OrdersStatistics", filters);
      commit("SORT_ORDERS_STATISTICS", res.data);
    },
    async getRateingStatistics({ commit }) {
      let res = await $http.post("/Dashboard/RateingStatistics");
      commit("SORT_RATEING_STATISTICS", res.data);
    },
    async getBuyForMeStatistics(
      { commit },
      filters = {
        FromDate: new Date().toISOString(),
        ToDate: new Date().toISOString(),
      }
    ) {
      if (filters.FromDate === "") {
        filters.FromDate = filters.ToDate;
      } else if (filters.ToDate === "") {
        filters.ToDate = filters.FromDate;
      }
      let res = await $http.post("/Dashboard/BuyForMeStatistics", filters);
      commit("SORT_BUYFORME_STATISTICS", res.data);
    },
  },
};
