import $http from "@/plugins/axios.js";

export default {
  state: {
    shelves: null,
    shelf: null,
  },
  getters: {
    shelves: (state) => state.shelves,
    shelf: (state) => state.shelf,
  },
  mutations: {
    SET_SHELVES(state, data) {
      state.shelves = data;
    },
    SET_SHELF(state, data) {
      state.shelf = data;
    },
    SET_CHECK_SHELF(state, data) {
      state.shelf = data;
    },
  },
  actions: {
    async getShelves(
      { dispatch, commit },
      payload = { take: 10, page: 1, filters: {} }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/GetShelves", {
          PageNumber: payload.page,
          PageSize: payload.take,
          WarehouseFkID: payload.filters.warehouseId
            ? payload.filters.warehouseId
            : null,
          Search: payload.filters.search ? payload.filters.search : "",
        });
        dispatch("toggleIsLoading", false);
        commit("SET_SHELVES", res.data.ResponseData);
      } catch (err) {
        dispatch("toggleIsLoading", false);
        commit("SET_SHELVES", null);
        throw err;
      }
    },
    async getShelfByID({ commit, dispatch }, id) {
      dispatch("toggleIsLoading", true);
      try {
        const res = await $http.post("/dashboard/GetShelf", {
          Id: id,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_SHELF", res.data.ResponseData[0]);
      } catch (err) {
        dispatch("toggleIsLoading", false);
        commit("SET_SHELF", null);
        throw err;
      }
    },
    async checkShelf({ commit, dispatch }, payload) {
      console.log(payload);
      dispatch("toggleIsLoading", true);
      try {
        const res = await $http.post("/dashboard/CheckShelf", {
          WarehouseId: payload.warehouseId,
          UserId: payload.userId,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_CHECK_SHELF", res.data.ResponseData);
      } catch (err) {
        dispatch("toggleIsLoading", false);
        commit("SET_CHECK_SHELF", null);
        throw err;
      }
    },
    async addShelf({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/AddShelf", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    async editShelf({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/EditShelf", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    async deleteShelf({ dispatch, commit }, id) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/DeleteShelf", { Id: id });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        return;
      }
    },
  },
};
