<template>
  <div class="alerts-container">
    <div class="alerts">
      <b-alert
        :show="globalAlerts.duration"
        dismissible
        @dismissed="globalAlerts.duration = 0"
        fade
      >
        <div class="alert-content">
          <div
            class="icon"
            :class="{
              'text-success': globalAlerts.alertVariant == 'success',
              'text-primary': globalAlerts.alertVariant == 'primary',
              'text-danger': globalAlerts.alertVariant == 'danger',
              'text-warning': globalAlerts.alertVariant == 'warning',
              'text-info': globalAlerts.alertVariant == 'info',
            }"
          >
            <i
              :class="{
                'fa-regular fa-circle-check':
                  globalAlerts.alertVariant == 'success',
                'fa-solid fa-spinner fa-spin':
                  globalAlerts.alertVariant == 'primary',
                'fa-solid fa-xmark': globalAlerts.alertVariant == 'danger',
                'fa-solid fa-triangle-exclamation':
                  globalAlerts.alertVariant == 'warning',
                'fa-solid fa-exclamation': globalAlerts.alertVariant == 'info',
              }"
            ></i>
          </div>
          <div class="content">
            {{ globalAlerts.alertMessage }}
          </div>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["globalAlerts"]),
  },
};
</script>
