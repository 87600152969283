import $http from "@/plugins/axios";

export default {
  state: {
    users: null,
    user: null,
  },
  getters: {
    users: (state) => state.users,
    user: (state) => state.user,
  },
  mutations: {
    SET_USERS(state, data) {
      state.users = data;
    },
    SET_USER(state, data) {
      state.user = data;
    },
  },
  actions: {
    async getUsers(
      { dispatch, commit },
      payload = { take: 10, page: 1, filters: {} }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/Dashboard/getusers", {
          PageNumber: payload.page,
          PageSize: payload.take,
          UserType: payload.filters.userType
            ? payload.filters.userType
            : "CUSTOMER",
          Search: payload.filters.search ? payload.filters.search : "",
        });
        res.data.ResponseData.Data.forEach((user) => {
          user.USER_TAG = `${user.FULL_NAME} - ${user.USERNAME}`;
        });
        dispatch("toggleIsLoading", false);
        commit("SET_USERS", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_USERS", null);
      }
    },
    async getUserByID({ commit, dispatch }, id) {
      dispatch("toggleIsLoading", true);
      try {
        const res = await $http.post("/dashboard/getuser", {
          Id: id,
        });
        res.data.ResponseData[0].USER_TAG = `${res.data.ResponseData[0].FULL_NAME} - ${res.data.ResponseData[0].USERNAME}`;
        dispatch("toggleIsLoading", false);
        commit("SET_USER", res.data.ResponseData[0]);
      } catch (err) {
        dispatch("toggleIsLoading", false);
        commit("SET_USER", null);
        throw err;
      }
    },
    async addUser({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/adduser", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    async editUser({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/edituser", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    async deleteUser({ dispatch, commit }, id) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/deleteuser", { Id: id });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        return;
      }
    },
  },
};
