import $http from "@/plugins/axios.js";

export default {
  state: {
    dashboardSettings: null,
    howToBook: null,
    services: null,
    brands: null,
    currencies: null,
    slides: null,
    abouts: null,
    servicePrices: null,
    flatRates: null,
    deliverys: null,
  },
  getters: {
    dashboardSettings: (state) => state.dashboardSettings,
    howToBook: (state) => state.howToBook,
    services: (state) => state.services,
    brands: (state) => state.brands,
    currencies: (state) => state.currencies,
    slides: (state) => state.slides,
    abouts: (state) => state.abouts,
    servicePrices: (state) => state.servicePrices,
    flatRates: (state) => state.flatRates,
    deliverys: (state) => state.deliverys,
  },
  mutations: {
    SET_DASHBOARD_SETTINGS(state, data) {
      state.dashboardSettings = data;
    },
    SET_CURRENCIES(state, data) {
      state.currencies = data;
    },
    SET_HOW_TO_BOOK(state, data) {
      state.howToBook = data;
    },
    SET_SERVICES(state, data) {
      state.services = data;
    },
    SET_BRANDS(state, data) {
      state.brands = data;
    },
    SET_SLIDES(state, data) {
      state.slides = data;
    },
    SET_ABOUTS(state, data) {
      state.abouts = data;
    },
    SET_SERVICE_PRICES(state, data) {
      state.servicePrices = data;
    },
    SET_FLAT_RATES(state, data) {
      state.flatRates = data;
    },
    SET_DELIVERYS(state, data) {
      state.deliverys = data;
    },
  },
  actions: {
    // service price settings
    async getServicePrices(
      { dispatch, commit },
      payload = { take: 1000, page: 1 }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/GetServicePrices", {
          PageNumber: payload.page,
          PageSize: payload.take,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_SERVICE_PRICES", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_SERVICE_PRICES", null);
      }
    },
    async addServicePrice({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/AddServicePrice", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 400 ||
          err.response.status === 403
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    async editServicePrice({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/EditServicePrice", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم تعديل البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 400 ||
          err.response.status === 403
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    async deleteServicePrice({ dispatch, commit }, id) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/DeleteServicePrice", { Id: id });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        return;
      }
    },
    // end of service price settings
    // flat rate
    async getFlatRates(
      { dispatch, commit },
      payload = { take: 1000, page: 1 }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/GetFlatRates", {
          PageNumber: payload.page,
          PageSize: payload.take,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_FLAT_RATES", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_FLAT_RATES", null);
      }
    },
    async editFlatRate({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/EditFlatRate", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم تعديل البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 400 ||
          err.response.status === 403
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    // end of flat rate
    // delivery
    async getDeliverys(
      { dispatch, commit },
      payload = { take: 1000, page: 1 }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/GetDeliveryAmounts", {
          PageNumber: payload.page,
          PageSize: payload.take,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_DELIVERYS", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_DELIVERYS", null);
      }
    },
    async editDelivery({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/EditDeliveryAmount", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم تعديل البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 400 ||
          err.response.status === 403
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    // end of delivery
    // store settings
    async getDashboardSettings(
      { dispatch, commit },
      payload = { take: 1000, page: 1 }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/getStoreSittings", {
          PageNumber: payload.page,
          PageSize: payload.take,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_DASHBOARD_SETTINGS", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_DASHBOARD_SETTINGS", null);
      }
    },

    async editDashboardSettings({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/editStoreSitting", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم تعديل البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    // end of store settings

    // currencies
    async getCurrencies(
      { dispatch, commit },
      payload = { take: 1000, page: 1 }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/getcurrencies", {
          PageNumber: payload.page,
          PageSize: payload.take,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_CURRENCIES", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_CURRENCIES", null);
      }
    },

    async editCurrency({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/editCurrency", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم تعديل البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 400 ||
          err.response.status === 403
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },

    async addCurrency({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/addcurrency", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 400 ||
          err.response.status === 403
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },

    async deleteCurrency({ dispatch, commit }, id) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/deletecurrency", { Id: id });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        return;
      }
    },
    // end of currencies

    // how to book
    async getHowToBook(
      { dispatch, commit },
      payload = { take: 1000, page: 1 }
    ) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/gettips", {
          PageNumber: payload.page,
          PageSize: payload.take,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_HOW_TO_BOOK", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_HOW_TO_BOOK", null);
      }
    },

    async editHowToBook({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/edittip", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم تعديل البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 400 ||
          err.response.status === 403
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    // end of how to book

    // services
    async getServices({ dispatch, commit }, payload = { take: 1000, page: 1 }) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/getservices", {
          PageNumber: payload.page,
          PageSize: payload.take,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_SERVICES", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_SERVICES", null);
      }
    },

    async editService({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/editservice", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم تعديل البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 400 ||
          err.response.status === 403
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },
    // end of services

    // brands
    async getBrands({ dispatch, commit }, payload = { take: 1000, page: 1 }) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/getbrands", {
          PageNumber: payload.page,
          PageSize: payload.take,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_BRANDS", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_BRANDS", null);
      }
    },

    async editBrand({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/editbrand", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم تعديل البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 400 ||
          err.response.status === 403
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },

    async addBrand({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/addbrand", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },

    async deleteBrand({ dispatch, commit }, id) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/deletebrand", { Id: id });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        return;
      }
    },
    // end of brands

    // slides
    async getSlides({ dispatch, commit }, payload = { take: 1000, page: 1 }) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/GetSliderInfos", {
          PageNumber: payload.page,
          PageSize: payload.take,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_SLIDES", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_SLIDES", null);
      }
    },

    async editSlide({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/EditSliderInfo", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم تعديل البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 400 ||
          err.response.status === 403
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },

    async addSlide({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/AddSliderInfo", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },

    async deleteSlide({ dispatch, commit }, id) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/DeleteSliderInfo", { Id: id });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        return;
      }
    },
    // end of brands

    // about
    async getAbouts({ dispatch, commit }, payload = { take: 1000, page: 1 }) {
      dispatch("toggleIsLoading", true);
      try {
        let res = await $http.post("/dashboard/GetAboutUsInfos", {
          PageNumber: payload.page,
          PageSize: payload.take,
        });
        dispatch("toggleIsLoading", false);
        commit("SET_ABOUTS", res.data.ResponseData);
      } catch (err) {
        console.error(err);
        dispatch("toggleIsLoading", false);
        commit("SET_ABOUTS", null);
      }
    },

    async editAbout({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/EditAboutUsInfo", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم تعديل البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 400 ||
          err.response.status === 403
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },

    async addAbout({ dispatch, commit }, payload) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/AddAboutUsInfo", payload);
        dispatch("pushAlert", {
          alertVariant: "success",
          alertMessage: "تم اضافة البيانات بنجاح",
          duration: 3,
        });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        throw err;
      }
    },

    async deleteAbout({ dispatch, commit }, id) {
      dispatch("toggleBtnLoading", true);
      try {
        await $http.post("/dashboard/DeleteaboutUsInfo", { Id: id });
        dispatch("toggleBtnLoading", false);
        return commit;
      } catch (err) {
        console.error(err);
        if (
          err.response.status === 500 ||
          err.response.status === 403 ||
          err.response.status === 400
        ) {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: err.response.data.StatusDescription,
            duration: 3,
          });
        } else {
          dispatch("pushAlert", {
            alertVariant: "danger",
            alertMessage: "خلل في الاتصال حاول مجددا",
            duration: 3,
          });
        }
        dispatch("toggleBtnLoading", false);
        return;
      }
    },
    // end of about
  },
};
